<template>
  <div>
    <a-spin :spinning="loading">
      <div class="flex space alcenter">
        <div class="ft20 cl-main ftw600">订单详情</div>
        <a-button @click="$router.go(-1)">返回</a-button>
      </div>

      <div class="mt20">
        <div class="bg-w pd40" style="min-height: 800px;">

          <div class="">
            <div class="flex alcenter">
              <div class="mall-goods_order-detail-tag ml20" style="background: #4772FF;"
                   v-if="order.logistics_type==1">快递单</div>
              <div class="mall-goods_order-detail-tag ml20" style="background: #00BA26;"
                   v-if="order.logistics_type==2">自提单</div>
              <div class="ml8">
                <div class="flex alcenter">
                  <div class="flex alcenter">
                    <div class="ft14 ftw400 cl-info">订单编号：</div>
                    <div class="ft14 ftw600 cl-main">{{order.order_sn}}</div>
                  </div>

                  <div class="flex alcenter ml40">
                    <div class="ft14 ftw400 cl-info">下单时间：</div>
                    <div class="ft14 ftw600 cl-main">{{order.add_time}}</div>
                  </div>

                  <div class="flex alcenter ml40" v-if="order.day">
                    <div class="ft14 ftw400 cl-info text-over4">预约时间：</div>
                    <div class="ft14 ftw600 cl-theme text-over4">{{order.day}} {{order.start_time}}~{{order.day}} {{order.end_time}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt30">
            <div class="mall-goods_order-detail-info">
              <div class="flex ">
                <div class="pl40" style="width: 10%;border-right: 1px solid #EBEDF5;">
                  <div class="ft14 ftw500 cl-main">订单状态</div>
                  <div class="ft20 ftw600 cl-theme mt16" v-if="order.order_status==0">待付款</div>
                  <div class="ft20 ftw600 cl-theme mt16" v-if="order.order_status==1">待使用</div>
                  <div class="ft20 ftw600 cl-theme mt16" v-if="order.order_status==2">已完成</div>
                  <div class="ft20 ftw600 cl-theme mt16" v-if="order.order_status==-1">已取消</div>
                </div>
                <div class="" style="width: 25%;">
                  <div class="flex" style="border-right: 1px solid #EBEDF5; ">
                    <div class="ml40 text-over4">
                      <div class="ft14 ftw500 cl-main text-over4">付款信息</div>
                      <div class="mt16">
                        <div class="flex">
                          <div>
                            <div class="flex alcenter">
                              <div class="ft14 ftw400 cl-info text-over4">实付金额：</div>
                              <div class="ft14 ftw600 cl-main text-over4">
                                ¥{{order.need_pay}}</div>
                            </div>
                            <div class="flex alcenter mt10">
                              <div class="ft14 ftw400 cl-info text-over4">支付方式：</div>
                              <div class="ft14 ftw600 cl-main text-over4" v-if="order.status==1 || order.status ==-1">未支付</div>
                              <div class="ft14 ftw600 cl-main text-over4" v-else>
                                {{order.pay_type_text}}
                              </div>
                            </div>
                            <div class="flex alcenter mt10" v-if="order.pay_num">
                              <div class="ft14 ftw400 cl-info text-over4">支付单号：</div>
                              <div class="ft14 ftw600 cl-main text-over4" >{{order.pay_num}}</div>
                            </div>
                            <div class="flex alcenter mt10">
                              <div class="ft14 ftw400 cl-info text-over4">支付时间：</div>
                              <div class="ft14 ftw600 cl-main text-over4" >
                                {{order.pay_time}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="" style="width: 25%;">
                  <div class="ml40">
                    <div class="ft14 ftw500 cl-main">客户信息</div>
                    <div class="mt16">
                      <div class="flex">
                        <div>
                          <div class="flex alcenter">
                            <div class="ft14 ftw400 cl-info text-over4">客户姓名：</div>
                            <div class="ft14 ftw600 cl-main text-over4">
                              {{order.member.nick_name}}</div>
                          </div>
                          <div class="flex alcenter mt10">
                            <div class="ft14 ftw400 cl-info text-over4">手机号码：</div>
                            <div class="ft14 ftw600 cl-main text-over4">
                              {{order.member.mobile}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="" style="width: 25%;">
                  <div class="ml40">
                    <div class="ft14 ftw500 cl-main">联系人</div>
                    <div class="mt16">
                      <div class="flex">
                        <div>
                          <div class="flex alcenter">
                            <div class="ft14 ftw400 cl-info text-over4">联系人姓名：</div>
                            <div class="ft14 ftw600 cl-main text-over4">
                              {{order.contact_name}}</div>
                          </div>
                          <div class="flex alcenter mt10">
                            <div class="ft14 ftw400 cl-info text-over4">联系人号码：</div>
                            <div class="ft14 ftw600 cl-main text-over4">
                              {{order.contact_mobile}}</div>
                          </div>
                          <div class="flex  mt10" v-if="order.address_text!=''">
                            <div class="ft14 ftw400 cl-info text-over4">上门地址：</div>
                            <div style="width: calc(100% - 120px);">
                              <div class="ft14 ftw600 cl-main">
                                {{order.address_text}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="" v-if="order.elder" style="width: 25%;">
                  <div class="ml40">
                    <div class="ft14 ftw500 cl-main">长者信息</div>
                    <div class="mt16">
                      <div class="flex">
                        <div>
                          <div class="flex alcenter">
                            <div class="ft14 ftw400 cl-info text-over4">长者姓名：</div>
                            <div class="ft14 ftw600 cl-main text-over4">
                              {{order.elder.name}}</div>
                          </div>
                          <div class="flex alcenter mt10">
                            <div class="ft14 ftw400 cl-info text-over4">长者号码：</div>
                            <div class="ft14 ftw600 cl-main text-over4">
                              {{order.elder.mobile}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt30">
            <div class="flex wrap">
              <div class="mall-goods_order-detail-subcard-item" v-for="(item,index) in order.projectgoods" :key="item.id">
                <div class="flex  alcenter space">
                  <div class="flex alcenter">
                    <img v-if="item.project_cover_img != null" :src="item.project_cover_img" />
                    <div class="ml10">
                      <div class="ft14 ftw500 cl-info" style="width: 60%;">
                        {{item.project_name}}
                      </div>

                    </div>
                    <div style="margin-left: 200px;" class="ft14 ftw500 cl-info flex alcenter">
                      <div>¥{{item.project_price}}</div>
                      <div class="ml8">购买数量x{{item.buy_num}}</div>
                      <div class="ml8">售后数量x{{item.after_sum}}</div>
                      <div class="ml8">预约数量x{{item.appointment_count}}</div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

          <div class="flex end mt10">
            <div class="flex">
              <div class="ft12 ftw400 cl-info">
                <div class="flex end">合计</div>
                <div class="flex end mt8" v-if="order.logistics_type==1">运费</div>
                <div class="flex end mt8" v-if="order.coupon_money>0">优惠券抵扣</div>
                <div class="flex end mt8" v-if="order.integral_balance>0">积分抵扣</div>
                <div class="flex end mt8" v-if="order.pay_type=='offline' && order.youhui_balance > 0">优惠金额</div>
                <div class="flex end mt8 ft14 ftw500 cl-black">订单金额</div>
              </div>

              <div class="ml60 ft12 ftw400 cl-info">
                <div class="flex end">¥{{order.total_price}}</div>
                <div class="flex end mt8" v-if="order.logistics_type==1">¥{{order.freight}}</div>
                <div class="flex end mt8" v-if="order.coupon_money>0">¥{{order.coupon_money}}</div>
                <div class="flex end mt8" v-if="order.integral_balance>0">¥{{order.integral_balance}}
                </div>
                <div class="flex end mt8 ft14 ftw500 cl-black">¥{{order.need_pay}}</div>
              </div>
            </div>
          </div>
          <a-table rowKey="appointment_id" :columns="columns" :pagination="pagination"
                   :data-source="apponintmentLists" :loading="loading">
            <div style="text-align: center;" slot="day" slot-scope="day,record">
              <div>
                <div  style="display: contents" class="ft12 ftw400 ">{{day}}</div>
                <div  class="cl-theme">{{record.start_time}}-{{record.end_time}}</div>
              </div>
            </div>

            <div class="flex alcenter left" slot="project_id" slot-scope="project_id,record">
              <img :src="record.project_cover_img"  class="projetc-cover-img" />
              <div class="ml10 " style="text-align: left;">
                <div class="text-over4">{{record.project_name}}</div>
                <div v-if="record.project_sku_name!=''" class="flex alcenter">
                  <div class="ft12 ftw400 cl-notice">规格：</div>
                  <div class="ft12 ftw400 cl-notice text-over4">{{record.project_sku_name}}</div>
                </div>
                <div class="flex alcenter">
                  <div class="ft12 ftw400 cl-notice">价格：</div>
                  <div class="ft12 ftw400 cl-notice text-over4">{{record.total_price}}</div>
                </div>
              </div>
            </div>

            <div class="flex alcenter left" slot="serviceman" slot-scope="serviceman,record">
              <div class="flex alcenter center" v-if="serviceman">
                <img v-if="serviceman.face!=null" :src="serviceman.face" class="member-index-face" />
                <img v-else src="../../assets/image/woyaoyou_head_defult@2x.png" class="member-index-face" />
                <div class="ml5" style="text-align: left;">
                  <div>{{serviceman.name}}</div>
                  <div class="mt2">{{serviceman.mobile}}</div>
                </div>
              </div>
              <div class="flex alcenter center" v-else>未分配</div>
            </div>

            <div class="flex alcenter center" slot="status" slot-scope="status,record">
              <div v-if="status==1 || status==4 || status==3">{{record.status_means}}</div>
              <div v-if="status==8 || status==-1" class="cl-notice">{{record.status_means}}</div>
            </div>

            <div class="flex alcenter center" slot="is_paid" slot-scope="is_paid,record">
              {{is_paid==1 ? '已支付'+record.need_pay : '未支付'}}
            </div>



          </a-table>
        </div>
      </div>
    </a-spin>
  </div>

</template>

<script>
export default {
  data() {
    return {
      loading: false,
      order_id: 0,
      order: {

      },
      pagination: {
        current: 1,
        pageSize: 10, //每页中显示10条数据
        total: 0,
        showTotal:false,
      },
      apponintmentLists:[],
      columns:[],
    }
  },
  created() {
    if (!this.$route.query.id) {
      this.order_id = 0;
    } else {
      this.order_id = parseInt(this.$route.query.id);
    }
    this.loaddata();
  },
  methods: {
    loaddata() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http.api('admin/getProjectOrderDetail', {
        order_id: this.order_id
      }).then(res => {
        this.order = res.detail;
        this.apponintmentLists = res.apponintmentLists;
        this.columns=res.columns;

        this.loading = false;
      }).catch(res => {
        console.log(res);
        this.loading = false;
        this.$router.go(-1);
      })
    },
    overGoodsAct(){
      this.$confirm({
        title:'确认完成这个订单吗？',
        okText:"确定",
        okType:"danger",
        cancelText:"取消",
        onOk : ()=>{
          return new Promise((resolve,reject)=>{
            this.$http.api('admin/overGoodsOrder',{
              order_id:this.order_id,
            }).then(res=>{
              this.$message.success('完成成功');
              this.order.status=8;
            }).catch(res=>{
              console.log(res);
            }).finally(()=>{
              resolve();
            })
          })
        }
      })
    },
    deliverGoodsAct(){
      this.$confirm({
        title:'确认发货这个订单吗？',
        okText:"确定",
        okType:"danger",
        cancelText:"取消",
        onOk : ()=>{
          return new Promise((resolve,reject)=>{
            this.$http.api('admin/deliverGoodsOrder',{
              order_id:this.order_id,
            }).then(res=>{
              this.$message.success('发货成功');
              this.order.status=3;
            }).catch(res=>{
              console.log(res);
            }).finally(()=>{
              resolve();
            })
          })
        }
      })
    },

    writeOffAct(){
      this.$confirm({
        title:'确定核销这个订单吗？',
        okText:"确定",
        okType:"danger",
        cancelText:"取消",
        onOk : ()=>{
          return new Promise((resolve,reject)=>{
            this.$http.api('admin/writeOffOrder',{
              order_id:this.order_id,
            }).then(res=>{
              this.$message.success('核销成功');
              this.order.status=8;
            }).catch(res=>{
              console.log(res);
            }).finally(()=>{
              resolve();
            })
          })
        }
      })
    },
  }
}
</script>

<style>
.mall-goods_order-detail-tag {
  padding: 1px 8px;
  border-radius: 2px;

  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 16px;
}

.mall-goods_order-detail.tag {
  padding: 1px 4px;
  background: rgba(71, 114, 255, 0.1);
  border-radius: 2px;

  font-size: 12px;
  font-weight: 500;
  color: #4772FF;
}


.mall-goods_order-detail-info {
  background: #F9F9F9;
  border: 1px solid #EBEDF5;
  padding: 24px 0px;
}

.mall-goods_order-detail-subcard-item {
  width: 100%;
  height: 100px;
  background: #FFFFFF;
  border: 1px solid #EBEDF5;
  margin-bottom: 20px;
  padding: 20px;
}

.mall-goods_order-detail-subcard-item img {
  width: 75px;
  height: 60px;
}

.button-deliver {
  width: 96px;
  height: 40px;
  background: #4772FF;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
</style>
